import React from "react";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import Home1 from "../../assets/images/home1.png";
import Home2 from "../../assets/images/home2.png";
import Home3 from "../../assets/images/home3.png";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import styles from "./Home.module.css";
import ContactButton from "../../components/Buttons/ContactButton";
import PackagesButton from "../../components/Buttons/PackagesButton";
import { useNavigate, useLocation } from "react-router-dom";

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleScrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleContactClick = () => {
    if (location.pathname === "/") {
      handleScrollTo("contactSection");
    } else {
      navigate("/contactSection");
    }
  };

  const handlePackageClick = () => {
    navigate("/packages");
  };

  return (
    <Box
      id="home"
      padding={"6px"}
      sx={{ margin: { xs: "0px 10px", sm: "0px 20px", md: "0px 90px" } }}
    >
      <Grid container mt="10px">
        {/* Left Section - Text */}
        <Grid item xs={12} md={4}>
          <Box
            className={styles.fadeIn}
            sx={{ flexGrow: 1 }}
            textAlign={{ xs: "center", md: "left" }}
          >
            {["Making", "Your", "Vision"].map((text, index) => (
              <Typography
                key={index}
                variant="h2"
                sx={{
                  fontFamily: "Playfair Display, serif",
                  fontWeight: 500,
                  fontSize: { xs: "50px", sm: "70px", md: "74px" }, // Adjusted font size for mobile
                  marginBottom: 1,
                  lineHeight: 1.2,
                  display: { xs: "inline-block", md: "block" },
                  color: "#222",
                  marginRight: { xs: "16px", sm: "20px" },
                }}
              >
                {text}
              </Typography>
            ))}
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Playfair Display, serif",
                fontWeight: 500,
                fontSize: { xs: "50px", sm: "70px", md: "74px" }, // Adjusted font size for mobile
                marginBottom: 4,
                lineHeight: 1.2,
                color: "#222",
                whiteSpace: "nowrap",
                zIndex: 1,
                position: "relative",
              }}
            >
              Come to{" "}
              <span
                style={{
                  display: "inline-block",
                  whiteSpace: "normal",
                }}
              >
                Li<span style={{ color: { md: "#fff" } }}>fe</span>
              </span>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                gap: "20px",
              }}
            >
              <ContactButton onClick={handleContactClick} />
              <PackagesButton onClick={handlePackageClick} />
            </Box>
          </Box>
        </Grid>

        {/* Right Section - Images & Explore More */}
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", sm: "row" },
              gap: 6,
            }}
          >
            {/* First Image with Explore More */}
            <Grid item xs={12} md={4}>
              <Box sx={{ flex: 1, position: "relative" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "DM Sans",
                      fontWeight: "700",
                      fontSize: { xs: "14px", sm: "16px", md: "20px" },
                      lineHeight: "30px",
                    }}
                  >
                    Explore More
                  </Typography>
                  <EastRoundedIcon sx={{ fontSize: "30px", color: "#000" }} />
                </Box>
                <Box sx={{ marginTop: "30px", ml: { xs: "0px", sm: "-80px" } }}>
                  <Box
                    alt="Image1"
                    sx={{
                      width: "100%",
                      height: { xs: "250px", sm: "500px", md: "480px" },
                      backgroundImage: `url(${Home1})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
                      maxWidth: { md: "300px", sm: "300px" },
                    }}
                  />
                </Box>
              </Box>
            </Grid>

            {/* Second Image */}
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  flex: 1,
                  ml: { md: "-40px", xs: "0px", sm: "0px" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: { sm: "40px" },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    // maxWidth: {sm:"300px",md:"400px" },

                    height: { xs: "250px", sm: "500px", md: "550px" },
                    backgroundImage: `url(${Home2})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
                  }}
                  alt="Image2"
                />
              </Box>
            </Grid>

            {/* Third Image with Text */}
            <Grid item xs={12} md={4}>
              <Box
                alt="Image3"
                sx={{
                  width: "100%", // Full width
                  maxWidth: "400px",
                  height: { xs: "250px", sm: "400px", md: "430px" },
                  backgroundImage: `url(${Home3})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
                  marginTop: { sm: "40px", md: "60px" },
                }}
              />
              <Typography
                variant="body1"
                className={styles.fadeIn}
                sx={{
                  mt: 2,
                  color: "#000",
                  fontSize: { xs: "12px", sm: "14px", md: "16px", lg: "16px" },
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                Cool Shots, Awesome Memories. Experience the Difference of
                Professional Capture.
              </Typography>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
