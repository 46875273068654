import React, { useState } from 'react';
import axios from 'axios';
import {
  Box,
  Divider,
  Paper,
  Typography,
  TextField,
  IconButton,
  Grid,
} from "@mui/material";
import ContactUsImg from "../../../assets/images/contactus.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SettingsPhoneOutlinedIcon from "@mui/icons-material/SettingsPhoneOutlined";
import Submit from "../../Buttons/Submit";
import CFLogo from "../../../assets/images/footerLogo.png";
import { Element } from "react-scroll";

// Reusable InputField Component
const InputField = ({
  label,
  placeholder,
  name,
  type = "text",
  required = false,
  onChange,
  value
}) => (
  <Box sx={{ mb: 2 }}>
    <Typography
      variant="subtitle1"
      sx={{
        fontFamily: "DM Sans",
        fontWeight: 700,
        fontSize: "16px",
      }}
    >
      {label}
    </Typography>
    <TextField
     value={value}
     onChange={onChange}
      fullWidth
      required={required}
      placeholder={placeholder}
      name={name}
      type={type}
      variant="outlined"
      sx={{
        backgroundColor: "#EAECE9",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },
      }}
    />
  </Box>
);

const Contact = () => {

  const [error, setError] = useState(null);

  const [name, setName] = useState('');
  const [contactnumber, setContactnumber] = useState('');
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');

  const postData = async () => {

    if(name ==='')
    {
      alert('Enter Name');
      return;
    }

    if(contactnumber === '')
    {
      alert('Enter Contact number');
      return;
    }

    if(email != '')
    {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert('Enter a valid email address');
        return;
      }
    }

    const apiUrl = "https://teazelapi.coralframes.com/api/contactus";
    const payload = {
      NAME: name,
      CONTACTNUMBER: contactnumber,
      EMAIL: email,
      COMPANY: company,
      DATASOURCE: "WEBSITE"
    };

    try {
      const response = await axios.post(apiUrl, payload);
      if(response?.data?.IsSuccess)
      {
        alert('Thank You for Reaching Out! Our team will get back to you as soon as possible.')
      }else{
        alert('Something went wrong!. Please try again.')
      }
    
    } catch (err) {
      setError(err.message); // Handle error
    }
  };


  return (
    <Element name="contactSection" id="contactSection">
      {" "}
      {/* Fixed: Added id here */}
      <Box
        sx={{
          px: { xs: 2, sm: 3, md: 5, lg: 6 },
          maxWidth: "1600px",
          mx: "auto",
          my: 4,
          bgcolor: "#EAECE9",
        }}
      >
        <Grid container spacing={8} justifyContent="space-between">
          {/* Form Section */}
          <Grid item xs={12} md={7}>
            <Paper elevation={0} sx={{ padding: { xs: 3, md: 2 } }}>
              <Typography
                variant="h3"
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "50px", sm: "60px", md: "74px" },
                }}
              >
                Reach out to Us
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: 3,
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                Have Questions or Feedback? We’re here to help.
              </Typography>
              <Divider sx={{ my: 1, backgroundColor: "#CEC8C3", height: 2 }} />

              {/* Form Fields */}
              <Box component="form" sx={{ fontSize: "16px" }}>
                <InputField
                  label="Name"
                  placeholder="Name"
                  name="name"
                  required
                  value={name} 
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
                <InputField
                  label="Contact Number"
                  placeholder="Contact Number"
                  name="contactNumber"
                  type="number"
                  required
                  value={contactnumber} 
                  onChange={(e) => {
                    setContactnumber(e.target.value);
                  }}
                />
                <InputField
                  label="Email Address"
                  placeholder="Email"
                  name="email"
                  type="email"
                  required
                  value={email} 
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <InputField
                  label="Company"
                  placeholder="Company"
                  name="company"
                  value={company} 
                  onChange={(e) => {
                    setCompany(e.target.value);
                  }}
                />

                {/* Submit Button */}
                <Submit 
                onClick={() => postData()}
                />
              </Box>
            </Paper>
          </Grid>

          {/* Image & Info Section */}
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src={ContactUsImg}
              alt="Contact Us Illustration"
              sx={{
                width: "100%",

                height: "auto",
              }}
            />
            <Paper sx={{ padding: 2 }}>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Box
                  component="img"
                  src={CFLogo}
                  alt="Coralframes Logo"
                  sx={{ maxWidth: "150px" }}
                />
              </Box>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                Registered Address :
                <br />
                Sierra Cartel,#1207 /343 ,
                <br />
                9th Main, 7th Sector,
                <br />
                Hsr Layout,Bangalore,
                <br />
                Karnataka,India -560068
              </Typography>

              {/* Social Icons */}
              <Box sx={{ display: "flex", gap: 1 }}>
                <IconButton
                  component="a"
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 25 }} />
                </IconButton>

                <IconButton
                  component="a"
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 25 }} />
                </IconButton>

                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 25}} />
                </IconButton>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Element>
  );
};

export default Contact;
