import { Button } from "@mui/material";
import React from "react";

const ContactNow = ({ onClick }) => {
  return (
    <Button
      variant="contained"
      sx={{
        backgroundColor: "#D2996C",
        color: "white",
        fontFamily: "DM Sans",
        fontWeight: 500,
        fontSize: "16px",
        padding: "10px 20px",
        borderRadius: "0px",
        boxShadow: 3,
        "&:hover": {
          backgroundColor: "#B5894A",
        },
      }}
    >
      Contact Now
    </Button>
  );
};

export default ContactNow;
