import React from "react";
import { Typography, Box, Paper, IconButton, Grid } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import SettingsPhoneOutlinedIcon from "@mui/icons-material/SettingsPhoneOutlined";
import SachinFounder from "../../../src/assets/images/sachin.png";
import HarijithChakrapani from "../../../src/assets/images/HarijithChakrapani.png";
import RoyalNithin from "../../../src/assets/images/Royalnithinsequeira.png";

const OurTeam = () => {
  return (
    <section id="team">
      <Box
        sx={{
          px: { xs: 2, sm: 3, md: 5, lg: 8 },
          maxWidth: "1600px",
          mx: "auto",
          py: 1,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Playfair Display",
            fontWeight: 500,
            fontSize: { xs: "48px", md: "74px" },
          }}
        >
          Our Team
        </Typography>

        <Paper sx={{ p: 4, width:"90%"}}>
          <Grid
            container
            spacing={2}
            sx={{
              flexDirection: { xs: "column", md: "row" },
              alignItems: "center",margin:"auto"
            }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "36px", md: "74px" },
                  position: "relative",
                }}
              >
                I Really Love to{" "}
                <span style={{ position: "relative", whiteSpace: "nowrap" }}>
                  Talk with <span style={{ color: "#FFFFFF" }}>People</span>
                </span>
              </Typography>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "24px", md: "32px" },
                  mt: 1,
                }}
              >
                Sachin Kocharekar
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "20px", md: "16px" },
                  color: "#747576",
                }}
              >
                Founder
              </Typography>

              <Box sx={{ display: "flex", gap: 1, my: 2 }}>
                <IconButton
                  component="a"
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 28 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 28 }} />
                </IconButton>
              </Box>

              {/* <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "16px" },
                }}
              >
                While creating photography we can get inspired by anything: From
                films to exhibitions, from good music to special fabrics, but
                also by stories and beautiful memories.
              </Typography> */}
            </Grid>

            <Grid item xs={12} sm={6} md={9}>
              <img
                src={SachinFounder}
                alt="Sachin the great founder"
                style={{ width: "100%",maxWidth:"800px", height: "auto" }}
              />
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 400,
                  fontSize: { xs: "16px", md: "16px" },
                  mt: 2,
                }}
              >
                Sachin is a Senior Manager at an Indian MNC. He has been a
                developer and continues to write code every day. He likes to
                work in the fashion industry, which means he always gets
                inspired by the person, the collection, or the company that he
                works with.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ mt: 4, flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <img
                src={HarijithChakrapani}
                alt="Harijith Chakrapani"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid  #EAECE9",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "36px", md: "74px" },
                }}
              >
                Self Confidence is the Best Fashion
              </Typography>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "16px", md: "16px" },
                  mt: 1,
                }}
              >
                Harijith Chakrapani
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "20px", md: "20px" },
                  color: "#747576",
                }}
              >
                Founder
              </Typography>

              <Box sx={{ display: "flex", gap: 1, my: 2 }}>
                <IconButton
                  component="a"
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Box>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "20px" },
                }}
              >
                Hari uses the knowledge he has gained as a fashion photographer
                to photograph people who have an eye for style and quality.
                Through his years of experience, he knows how to get the best
                out of the person in front of his lens, regardless of their
                experience in front of the camera.
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            sx={{ mt: 4, flexDirection: { xs: "column", md: "row" } }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                sx={{
                  fontFamily: "Playfair Display",
                  fontWeight: 500,
                  fontSize: { xs: "36px", md: "84px" },
                }}
              >
                Quality of being Grateful and Stylish{" "}
              </Typography>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "24px", md: "24px" },
                  mt: 1,
                }}
              >
                Royal Nithin Sequeira{" "}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 500,
                  fontSize: { xs: "20px", md: "20px" },
                  color: "#747576",
                }}
              >
                Founder
              </Typography>

              <Box sx={{ display: "flex", gap: 1, my: 2 }}>
                <IconButton
                  component="a"
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <InstagramIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ color: "#000" }}
                >
                  <LinkedInIcon sx={{ fontSize: 30 }} />
                </IconButton>
                <IconButton
                  component="a"
                  href="tel:+123456789"
                  sx={{ color: "#000" }}
                >
                  <SettingsPhoneOutlinedIcon sx={{ fontSize: 30 }} />
                </IconButton>
              </Box>

              <Typography
                sx={{
                  fontFamily: "DM Sans",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "20px" },
                }}
              >
                Over the years Royal has developed his expertise and
                photographic style with much love and dedication. Although he is
                the youngest photographer, He had mastered the art of creating
                visually appealing photo series.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <img
                src={RoyalNithin}
                alt="Royal Nithin"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "50px solid #EAECE9",
                }}
              />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </section>
  );
};

export default OurTeam;
