import React from "react";
import Button from "@mui/material/Button";

const  Submit = ({onClick}) => {
  return (
    <Button
    onClick={() => onClick()}
      variant="contained"
      size="medium"
      sx={{
        fontFamily: "DM Sans",
        fontSize: "20px",
        fontWeight: 500,
        color: "#ffffff",
        bgcolor: "#000",
        padding: "15px 45px",
        textTransform: "none",
        borderRadius: "0px" ,
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)", 
        transition: "background-color 0.3s, transform 0.3s", 

        "&:hover": {
          bgcolor: "#333", 
          transform: "translateY(-2px)", 
        },
        "&:focus": {
          outline: "none",
        },
        "&:active": {
          transform: "translateY(1px)",
          boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        },
      }}
    >Submit
    </Button>
  );
};

export default Submit;




















