import React from "react";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import ServicesImg from "../../../assets/images/services.png";
import ServiceAppointment from "../../../assets/images/servicesAppointment.png";
import ViewPackageDetails from "../../Buttons/ViewPackageDetails";
import SouthIcon from "@mui/icons-material/South";

const Services = () => {
  return (
    <Box
      sx={{
        position: "relative",
        backgroundColor: "#EAECE9",
        marginTop:"60px",
      }}
    >
      <Box
        sx={{
          px: { xs: 2, sm: 3, md: 8, lg: 8 },
          maxWidth: "1600px",
          mx: "auto",
        }}
      >
        <Grid container spacing={4}>
          {/* Text Section */}
          <Grid
            item
            md={4}
            xs={12}
            sx={{
              textAlign: { sm: "center", md: "left", xs: "center", lg: "left" },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: { xs: "14px", md: "16px" },
                color: "#000000",
                mt: 2,
              }}
            >
              PHOTOGRAPHY OR VIDEOGRAPHY
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Playfair Display",
                fontWeight: 500,
                fontSize: { xs: "70px", sm: "74px", md: "74px" },
                lineHeight: 1.8,
              
               
              }}
            >
              Discover
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "DM Sans",
                fontWeight: 400,
                fontSize: "16px",
                lineHeight: "30px",
                textAlign: { md: "right", sm: "center", xs: "center" },
              
                width: { md: "75%", sm: "100%", xs: "100%" },
              }}
            >
              Distinctive photography for private individuals and companies with
              style and an eye for quality.
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Playfair Display",
                fontWeight: 500,
                fontSize: { xs: "70px", sm: "74px", md: "74px" },
                lineHeight: 1.8,
              
          
              }}
            >
              Packages
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            sm={12}
            xs={12}
            position="relative"
            sx={{
              textAlign: { sm: "center", md: "left", xs: "center", lg: "left" },
            }}
          >
            <Typography
              variant="h2"
              sx={{
                fontFamily: "Playfair Display",
                fontWeight: 500,
                fontSize: { xs: "70px", sm: "74px", md: "74px" },
                lineHeight: 1.2,
                color: "#000",
                mt: { md: "40%", sm: "0%", xs: "0%" },
                mb: { md: "150px", sm: "80px", xs: "60px" },
              }}
            >
              Our Best
            </Typography>
            <ViewPackageDetails />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mt: "20px",
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "left",
                  lg: "left",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#000", textAlign: "center", width: "60%" }}
              >
                Make an appointment for consultation
              </Typography>
              <SouthIcon
                sx={{
                  fontSize: "40px",
                  transform: "scaleY(1.5)",
                  color: "#000",
                }}
              />
            </Box>
          </Grid>

          {/* Image Section */}
          <Grid item md={4} xs={12}>
            <Box>
              <Box
                component="img"
                src={ServicesImg}
                alt="Service"
                sx={{
                  maxHeight: "650px",
                  width: "100%",
                  marginLeft: { md: "-50%", sm: "0%", xs: "0%" },
                  objectFit: "cover",
       
                  
                }}
                loading="lazy"
              />
              <img
                src={ServiceAppointment}
                alt="Service Appointment"
                style={{ width: "60%", marginLeft: "40%", marginTop: "-50%" }}
                loading="lazy"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          height: "15vh",
          backgroundColor: "#D2996C",
          marginTop: { md: "-5%", sm: "-15%", xs: "-15%" },
        }}
      />
    </Box>
  );
};

export default Services;
